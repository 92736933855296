/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-08-03 14:16:26
 * @LastEditors: llg
 * @LastEditTime: 2022-09-15 16:33:50
 */
import { doPost, post } from '../index'
// 订单列表
export const getOrderList = data => doPost('/api/v1/order/getOrderList')(data)
// 订单详情
export const getOrderDetailList = data => doPost('/api/v1/order/getOrderDetailList')(data)
// 指派
export const allocationOrder = data => doPost('/api/v1/order/allocationOrder')(data)
// 制作
export const makeOrder = data => post('/api/v1/order/makeOrder')(data)
// 拒绝
export const refuseOrder = data => doPost('/api/v1/order/refuseOrder')(data)
// 发货
export const deliverOrder = data => post('/api/v1/order/deliverOrder')(data)
// 完成
export const finishOrder = data => post('/api/v1/order/finishOrder')(data)
// 下载订单文件
export const downloadOrderFile = data => post('/api/v1/order/downloadOrderFile')(data)
// 退单
export const refundOrder = data => doPost('/api/v1/order/refundOrder')(data)
// 导出订单
export const exportOrderList = data => doPost('/api/v1/order/exportOrderList')(data)
// 导出子订单
export const exportOrderDetail = data => doPost('/api/v1/order/exportOrderDetail')(data)
// 修改页数
export const updateOrderPageNumber = data => doPost('/api/v1/order/updateOrderPageNumber')(data)
// 修改订单状态
export const resumeOrderStatus = data => doPost('/api/v1/order/resumeOrderStatus')(data)
// 修改物流
export const updateOrderDeliver = data => doPost('/api/v1/order/updateOrderDeliver')(data)
// 子订单退款
export const updateRefundOrderStatus = data => doPost('/api/v1/order/updateRefundOrderStatus')(data)
// 批量下载
export const batchDownloadOrderFile = data => post('/api/v1/order/batchDownloadOrderFile')(data)
// 导出日志标签
export const exportLogLabelOrderList = data => doPost('/api/v1/order/exportLogLabelOrderList')(data)
// 获取订单配送规则
export const getDeliverOrderRule = data => post('/api/v3/order/getDeliverOrderRule')(data)

// 获取证件照订单列表
export const getPhotoOrderPageList = data => post('/api/v1/order/getPhotoOrderPageList')(data)

// 导出证件照订单
export const exportPhotoOrder = data => post('/api/v1/order/exportPhotoOrder')(data)

// 科大线上我的订单
export const getMyChargeOrderList = data => post('/api/v1/order/getMyChargeOrderList')(data)
// 科大线上我的订单导出日志标签
export const exportMyChargeLogLabelOrderList = data => doPost('/api/v1/order/exportMyChargeLogLabelOrderList')(data)
// 科大线上我的订单导出订单
export const exportMyChargeOrderList = data => doPost('/api/v1/order/exportMyChargeOrderList')(data)

// 获取订单文件页数
export const getOrderPageNumbers = data => post('/api/v1/order/getOrderPageNumbers')(data)


// 交易数据
export const getTransactionDataList = data => post('/api/v1/thirdTransaction/getTransactionDataList')(data)
export const getTransactionDataDetailList = data => post('/api/v1/thirdTransaction/getTransactionDataDetailList')(data)
export const exportTransactionDataList = data => post('/api/v1/thirdTransaction/exportTransactionDataList')(data)

// 退款日志
export const refundRecord = data => post('/api/v1/refundOrder/refundRecord')(data)

// 获取批量下载订单列表
export const getPrintOrderBatchDownList = data => post('/api/v1/printOrderBatchDown/getPrintOrderBatchDownList')(data)
// 批量下载
export const batchDownloadTask = data => post('/api/v1/printOrderBatchDown/batchDownloadTask')(data)
// 我的任务列表/全部任务列表
export const downTaskList = data => post('/api/v1/printOrderBatchDown/downTaskList')(data)
export const checkDown = data => post('/api/v1/printOrderBatchDown/checkDown')(data)
// 导出订单交易数据
export const exportOrderTradeList = data => post('/api/v1/order/exportOrderTradeList')(data)
// 快递单号下载
export const expressNumberDownload = data => post('/api/v1/order/expressNumberDownload')(data)
// 获取快递异常订单列表
export const getOrderSfDeliveryExceptionList = data => post('/api/v1/orderSfDeliveryException/getOrderSfDeliveryExceptionList')(data)
// 导出快递异常订单列表
export const exportOrderSfDeliveryExceptionList = data => post('/api/v1/orderSfDeliveryException/exportOrderSfDeliveryExceptionList')(data)
// 查看顺丰配送信息
export const sfLogisticsInfo = data => post('/api/v1/order/sfLogisticsInfo')(data)
// 获取订单支持的发货类型
export const getOrderCanDeliveryType = data => post('/api/v1/order/getOrderCanDeliveryType')(data)
// 获取售后列表
export const getOrderAfterSalesList = data => post('/api/v1/orderAfterSales/getOrderAfterSalesList')(data)
// 售后订单列表-导出
export const exportOrderAfterSalesList = data => doPost('/api/v1/orderAfterSales/exportOrderAfterSalesList')(data)
// 去处理
export const goHandleOrder = data => post('/api/v1/orderAfterSales/goHandleOrder')(data)
export const getOrderAfterSalesInfo = data => post('/api/v1/orderAfterSales/getOrderAfterSalesInfo')(data)
export const getExportFieldAfterSales = data => doPost('/api/v1/exportFields/getExportField')(data)



// 批量发货管理

// 获取导入发货列表
export const getShippingTemplate = data => post('/api/v1/batchDelivery/getShippingTemplate')(data)
// 获取导入发货模板
export const downloadDeliveryExcel = data => post('/api/v1/batchDelivery/downloadDeliveryExcel')(data)
// 批量发货
export const batchDelivery = data => post('/api/v1/batchDelivery/batchDelivery')(data)

// 批量导出订单详情任务
export const exportBatchOrderDetailList = data => doPost('/api/v1/order/exportBatchOrderDetailList')(data)
export const platformLogisticsInfo = data => post('/api/v1/order/platformLogisticsInfo')(data)


