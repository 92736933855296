/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-03-18 13:31:33
 * @LastEditors: llg
 * @LastEditTime: 2022-10-25 16:52:39
 */
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import 'element-ui/lib/theme-chalk/index.css';
import "@styles/reset.scss"; // 重置HTML样式、
import "@styles/app.scss"; // 全局样式
import "@styles/el-ui.scss"; // 优化element样式
import "@styles/dark.scss"; // 暗黑主题
import '../node_modules/66in-components/66in-components.css';
import ElementUI, { Loading } from "element-ui"; // 组件库
import SyUI from "66in-components"; // 66in-components
import components from "@/components"; // 注册全局组件
import derectives from "@/directives"; // 注册全局指令
import request from "./utils/request";
import vuescroll from "vuescroll"; // 滚动插件
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import moment from 'moment';
import elPatch from './utils/elPatch';

moment.locale('zh-cn');
Vue.use(components);
Vue.use(derectives);
Vue.use(vuescroll);
Vue.use(ElementUI, { size: "small", zIndex: 3000 }); // 全局设置element组件默认大小
Vue.use(SyUI);
Vue.use(Notifications, { velocity })
Vue.use(elPatch);

Vue.prototype.$http = request;
Vue.prototype.$store = store;
Vue.prototype.$moment = moment;
// Vue.prototype.$notifya = Notification
Vue.config.productionTip = false;
Vue.prototype.$setForm = function(target, origin) {
    for (const key in target) {
        target[key] = origin[key] ?? target[key]
    }
}
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')